@use "../../mixins/index.scss";
@import "../../mixins/breakpoints";
@import "../../mixins/colors";

.navigation {
  position: absolute;
  bottom: 0;
  height: 7vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  background: $primary;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  a {
    font-family: "Mallanna", sans-serif;
    font-weight: bolder;
    color: black;
    background: $primary;
    text-decoration: none;
    width: 50%;
    height: 100%;
    transition: all 0.5s ease-in-out;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    &:first-child {
      border-top-left-radius: 20px;
    }
    &:last-child {
      border-top-right-radius: 20px;
    }
    &:hover {
      background: $secondary;
      color: white;
      transition: all 0.5s ease-in-out;
    }
  }
}
