@use "../../mixins/index.scss";
@import "../../mixins/breakpoints";

.modal {
  width: 75%;
  border-radius: 24px;
  padding: 20px;
  background-color: white;
  section {
    display: flex;
    flex-direction: column;
    h1 {
      font-family: "Rubik", monospace;
      border-bottom: black solid 1px;
      padding-bottom: 15px;
      text-align: center;
    }
    p {
      font-family: "Rubik", monospace;
      padding-top: 15px;
      text-align: center;
    }
  }
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px;
}

.modalContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 0;
  transition: all 0.5s ease-in-out;
  &.active {
    opacity: 1;
    position: fixed;
    height: 100%;
    transition: all 0.5s ease-in-out;
    z-index: 10;
  }
}
@include md {
  .modal {
    display: none;
  }

  .modalHeader {
    display: none;
  }

  .modalContainer {
    display: none;
  }
}
