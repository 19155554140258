@use "../../mixins/index.scss";
@import "../../mixins/breakpoints";
@import "../../mixins/colors";

.activeLike {
  width: 95%;
  margin: 0 2.5%;
  padding: 10px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  color: $text;
  align-items: center;
  justify-content: center;
  background: $primary;
  border-radius: 15px;
  gap: 10px;
  overflow: hidden;
  border-bottom: solid $secondary 20px;
  h1 {
    font-family: "Rubik", monospace;
  }
  p {
    font-family: "Mallanna", sans-serif;
  }
  img {
    width: 200px;
    height: auto;
    border-radius: 15px;
  }
}

@include md {
  .activeLike {
    margin: 10px;
    padding: 10px;
    width: 40%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    color: $text;
    align-items: center;
    background: $primary;
    border-radius: 15px;
    gap: 10px;
    overflow: hidden;
    position: fixed;
    img {
      width: 200px;
      height: auto;
      border-radius: 15px;
    }
  }
}
