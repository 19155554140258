@import "../../mixins/index.scss";
@import "../../mixins/colors";

.paragraphItem {
  font-family: "Rubik", monospace;
  width: 75%;
  margin: 1%;
  font-size: 20px;
  color: $text;
}
