@use "../../mixins/index.scss";
@import "../../mixins/breakpoints";
@import "../../mixins/colors";

.likeCardsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.likeCard {
  position: relative;
  width: 100%;
  height: 100px;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  .likeCard1,
  .likeCard2,
  .likeCard3 {
    position: absolute;
    width: 100%;
    height: 100px;
    margin: 10px 10px;
  }
  .likeCard1 {
    background: $primary;
    border-radius: 15px;
    z-index: 3;
    color: $text;
    display: flex;
    align-items: center;
    transition: all ease-in 0.25s;
    .baseInformation {
      padding: 0 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      h3 {
        font-family: "Rubik";
      }
    }
    .imageContainer {
      width: 80px;
      height: 40px;
      overflow: hidden;
      position: relative;
      img {
        width: 80px;
        height: auto;
      }
    }
  }
  .likeCard2,
  .likeCard3 {
    z-index: 0;
    border-radius: 15px;
  }
  &:hover > .likeCard1 {
    background: $secondary;
    color: white;
    transition: all ease-in 0.25s;
  }
  &:hover > .likeCard2 {
    background: #d1dce3;
    color: white;
    transition: all ease-in 0.25s;
    transform: translate(5px, 5px);
    z-index: 2;
  }
  &:hover > .likeCard3 {
    background: #a0b2bd;
    color: white;
    transition: all ease-in 0.25s;
    transform: translate(10px, 10px);
    z-index: 1;
  }
}

@include md {
  .likeCardsContainerWithCurrentLike {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    right: 40px;
  }
  .likeCard {
    position: relative;
    width: 100%;
    height: 100px;
    margin: 10px 10px;
    display: flex;
    align-items: center;
    .likeCard1,
    .likeCard2,
    .likeCard3 {
      position: absolute;
      width: 100%;
      height: 100px;
      margin: 10px 10px;
    }
    .likeCard1 {
      background: $primary;
      border-radius: 15px;
      z-index: 3;
      color: $text;
      display: flex;
      align-items: center;
      transition: all ease-in 0.25s;
      .baseInformation {
        padding: 0 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        h3 {
          font-family: "Rubik";
        }
      }
      .imageContainer {
        width: 80px;
        height: 40px;
        overflow: hidden;
        position: relative;
        img {
          width: 80px;
          height: auto;
        }
      }
    }
    .likeCard2,
    .likeCard3 {
      z-index: 0;
      border-radius: 15px;
    }
    &:hover > .likeCard1 {
      background: $secondary;
      color: white;
      transition: all ease-in 0.25s;
    }
    &:hover > .likeCard2 {
      background: #d1dce3;
      color: white;
      transition: all ease-in 0.25s;
      transform: translate(5px, 5px);
      z-index: 2;
    }
    &:hover > .likeCard3 {
      background: #a0b2bd;
      color: white;
      transition: all ease-in 0.25s;
      transform: translate(10px, 10px);
      z-index: 1;
    }
  }
}
