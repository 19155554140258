@import "../mixins/index.scss";
@import "../mixins/colors";

.pages {
  position: relative;
  height: 90vh;
  overflow-y: scroll;
}

.likeContainer {
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

@include md {
  .likeContainer {
    width: 90%;
    margin: 0 5%;
    display: flex;
    flex-direction: row;
  }
}
