@use "../../mixins/index.scss";
@import "../../mixins/breakpoints";

.skills {
  margin-top: 50px;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  @include md {
    width: 50%;
    transition: all 0.5s ease-in-out;
  }
}

.skillItem {
  background: #000;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  margin: 2px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    background: #6b6a6a;
  }
  .tooltip {
    display: none;
  }
  .moreInfo {
    position: absolute;
    opacity: 1;
    color: white;
    bottom: 10px;
    right: 25px;
  }
  @include md {
    .moreInfo {
      position: absolute;
      opacity: 0;
      color: white;
      bottom: 10px;
      right: 25px;
    }
    .tooltip {
      font-family: "Rubik", monospace;
      padding: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 250px;
      position: absolute;
      left: 100%;
      transform: translate(0%, 85%);
      background-color: #fff;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      z-index: 0;
      opacity: 0;
      transition: all 0.25s ease-in-out;
      h1 {
        border-bottom: solid 1px black;
      }
    }
    &:hover > .tooltip {
      opacity: 1;
      transition: all 0.25 ease-in-out;
      z-index: 1;
    }
  }
}
